
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
  query {
    directus {
      messages {
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          field_required
          valid_email
          reset_password_instructions
          mismatch_passwords
          valid_phone_number
          languages_code {
            name
            code
          }
        }
      } 
    }
  }
`;

  export const useMessagesStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  