import React from "react";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import caret from "../../assets/images/caret.svg";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP,  } from "../../helpers/theme";
import ErrorMessage, {
  COLOR_ON_DARK,
  COLOR_ON_LIGHT,
} from "../shared/ErrorMessage/ErrorMessage";
import useRightToLeft from "../../hooks/useRightToLeft";

export type SelectProps = {
  placeHolder: string;
  options?: Record<string, any>[];
  labelField: string;
  valueField: string;
  error?: string;
  bgType?: "light" | "dark";
} & React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

const bgMap = {
  light: COLOR_ON_LIGHT,
  dark: COLOR_ON_DARK,
};

export default function Select({
  placeHolder = "",
  options = [],
  labelField,
  valueField,
  error = "",
  bgType = "dark",
  ...rest
}: SelectProps): JSX.Element {

  return (
    <div>
      <Container hasErrors={!!error} bgType={bgType}>
        <select {...rest}>
          <option selected value="" disabled>
            {placeHolder}
          </option>
          {options?.map((op, i) => (
            <option key={"op" + i} value={op[valueField]}>
              {op[labelField]}
            </option>
          ))}
        </select>
        <Caret src={caret} />
      </Container>
      {error && <ErrorMessage message={error} bgType={bgType} />}
    </div>
  );
}

const Caret = styled(SVG)`
  position: absolute;
  top: 50%;
  right: ${vwMobile(20)};
  transform: translateY(-50%);
  pointer-events: none;
  ${MEDIA_DESKTOP} {
    right: ${vwDesktop(20)};
  }
`;

const Container = styled.div<{
  hasErrors: boolean;
  bgType: string;
}>`
  position: relative;
  cursor: pointer;

  select {
    width: 100%;
    min-width: ${vwMobile(150)};
    height: ${vwMobile(56)};
    padding-inline: ${vwMobile(20)};

    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${rem(16)};
    color: #000000;
    font-weight: 700;
    outline: none;

    border: ${vwMobile(2)} solid
      ${(p) => (p.hasErrors ? bgMap[p.bgType] : "#000000")};

    background-color: #ffffff;
    appearance: none;

    &:focus {
      outline: ${vwMobile(3)} solid #75c154;
      ${MEDIA_DESKTOP} {
        outline: ${vwDesktop(3)} solid #75c154;
      }
    }

    ${MEDIA_DESKTOP} {
      min-width: ${vwDesktop(150)};
      height: ${vwDesktop(56)};
      padding-inline: ${vwDesktop(20)};
      border-width: ${vwDesktop(2)};
    }
  }

  & ${Caret} {
    right: ${(props) => (props.theme.isRTL && "94%")};
  }
`;
