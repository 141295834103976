import axios from "axios";

type ContactSalesFormPayload = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  businessName: string;
  products: string;
  distributors: string;
  city?: string;
  state?: string;
  country: {
    name?: string;
    code: string;
  };
  agreement: boolean;
};

type ProductInquiryFormPayload = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  city: string;
  state: string;
  country: {
    name?: string;
    code: string;
  };
  store: string;
  lotCode: string;
  orderNumber: string;
  subject: string;
  message: string;
};

const FORMSTACK_IDS = {
  PRODUCT: {
    fields: {
      firstName: "field_124924426",
      lastName: "field_124924540",
      email: "field_124924551",
      phoneNumber: "field_124924554",
      country: "field_124924562",
      state: "field_124924569",
      city: "field_124924611",
      store: "field_124924614",
      orderNumber: "field_124924621",
      lotCode: "field_124924707",
      subject: "field_124924723",
      message: "field_124924730",
    },
  },
  FOOD_SERVICE: {
    fields: {
      firstName: "field_124934300",
      lastName: "field_124933797",
      businessName: "field_124933664",
      country: "field_125153384",
      city: "field_125155338",
      stateUS: "field_125153413",
      stateCA: "field_125153414",
      phoneNumber: "field_124933982",
      email: "field_124933983",
      products: "field_124934017",
      distributors: "field_124934040",
      agreement: "field_125148247",
    },
  },
  MAILING_LIST: {
    fields: {
      email: "field_124936617",
    },
  },
};

interface FormstackServiceInterface {
  productInquiry: (payload: ProductInquiryFormPayload) => Promise<void>;
  contactSales: (payload: ContactSalesFormPayload) => Promise<void>;
  submitDynamicForms: (payload: any) => Promise<void>;
}

const validateFields = (
  data: { [key: string]: string | undefined },
  fixedValues: { [key: string]: string }
) => {
  const fields: { [key: string]: string } = {};
  Object.entries(data).forEach(([key, value]) => {
    const fixedField = fixedValues[key];
    fields[fixedField] = value as string;
  });
  return fields;
};

export const FormstackService: FormstackServiceInterface = {
  productInquiry: async ({
    email,
    state,
    city,
    firstName,
    lastName,
    country,
    phoneNumber,
    store,
    lotCode,
    message,
    orderNumber,
    subject,
  }) => {
    const data = {
      email,
      state,
      city,
      firstName,
      lastName,
      country: country?.name || country.code,
      phoneNumber,
      store,
      lotCode,
      message,
      orderNumber,
      subject,
    };
    const payload = {
      form: "4805397",
      data: validateFields(data, FORMSTACK_IDS.PRODUCT.fields),
    };
    await axios.post(
      "https://1258jh0b81.execute-api.us-west-2.amazonaws.com/beyondmeat/formstack/forms",
      payload
    );
  },

  contactSales: async ({
    email,
    distributors,
    products,
    state,
    city,
    businessName,
    firstName,
    lastName,
    country,
    phoneNumber,
    agreement,
  }) => {
    let stateUS = "";
    let stateCA = "";
    if (country.code === "US") {
      stateUS = state as string;
    }
    if (country.code === "CA") {
      stateCA = state as string;
    }
    const data = {
      email,
      distributors,
      products,
      stateUS,
      stateCA,
      city,
      businessName,
      firstName,
      lastName,
      country: country.code,
      phoneNumber,
      agreement: agreement ? "agree" : "reject",
    };

    const payload = {
      form: "4805570",
      data: validateFields(data, FORMSTACK_IDS.FOOD_SERVICE.fields),
    };

    await axios.post(
      "https://1258jh0b81.execute-api.us-west-2.amazonaws.com/beyondmeat/formstack/forms",
      payload
    );
  },

  submitDynamicForms: async ({ form, formstackId }) => {

    const formFields: { [key: string]: string }= {};

    if (form){
      Object.keys(form).map(fieldKey => {
        const formstackFieldId = `field_${form[fieldKey].formstackFieldId}`;
        formFields[formstackFieldId] = form[fieldKey].value;
      })
    }

    const payload = {
      form: formstackId,
      data: formFields
    };

    await axios.post(
      "https://1258jh0b81.execute-api.us-west-2.amazonaws.com/beyondmeat/formstack/forms",
      payload
    );

  },
};
