import { useIntl } from "./useIntl";
import { useMemo } from "react";
import { getTranslations } from "../helpers/translations";
import { useMessagesStaticQuery } from "../hooks/staticQueries/useMessagesStaticQuery";

export const useMessages = () => {
  const intl = useIntl();
  const data = useMessagesStaticQuery();

  const messagesData = useMemo(() => {
    const translatedData =
      getTranslations(data, "directus.messages.translations", intl.locale) ??
      {};

    return {
      fieldRequired: translatedData?.field_required,
      validEmail: translatedData?.valid_email,
      resetPasswordInstructions: translatedData?.reset_password_instructions,
      mismatchPasswords: translatedData?.mismatch_passwords,
      validPhoneNumber: translatedData?.valid_phone_number,
    };
  }, [data, intl]);

  return messagesData;
};
